import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98')
];

export const server_loads = [0,3,4,5,6,7,8,9,11,10,13,14,15,16,17,22];

export const dictionary = {
		"/(app)": [23,[2]],
		"/(app)/admin/api-clients": [~24,[2,3,4]],
		"/(app)/admin/api-clients/[id=id]": [~25,[2,3,4]],
		"/(app)/admin/api-clients/[id=id]/edit": [~26,[2,3,4]],
		"/(app)/admin/comments": [~27,[2,3,5]],
		"/(app)/admin/data-ingestion": [~28,[2,3,6]],
		"/(app)/admin/rfc": [~29,[2,3,7]],
		"/(app)/admin/system": [30,[2,3,8]],
		"/(auth)/auth/login/callback": [98],
		"/(app)/blog": [~31,[2]],
		"/(app)/data/airports": [~32,[2]],
		"/(app)/data/airports/new": [38,[2]],
		"/(app)/data/airports/[id=id]": [~33,[2]],
		"/(app)/data/airports/[id=id]/discussion": [~34,[2]],
		"/(app)/data/airports/[id=id]/edit": [~35,[2,9]],
		"/(app)/data/airports/[id=id]/rfc/diff": [~36,[2]],
		"/(app)/data/airports/[id=id]/rfc/history": [~37,[2]],
		"/(app)/data/airspaces": [~39,[2]],
		"/(app)/data/airspaces/imports": [~45,[2,11]],
		"/(app)/data/airspaces/imports/tool": [47,[12]],
		"/(app)/data/airspaces/imports/[id=id]": [~46,[2,11]],
		"/(app)/data/airspaces/new": [48,[2]],
		"/(app)/data/airspaces/[id=id]": [~40,[2]],
		"/(app)/data/airspaces/[id=id]/discussion": [~41,[2]],
		"/(app)/data/airspaces/[id=id]/edit": [~42,[2,10]],
		"/(app)/data/airspaces/[id=id]/rfc/diff": [~43,[2]],
		"/(app)/data/airspaces/[id=id]/rfc/history": [~44,[2]],
		"/(app)/data/exports": [~49,[2]],
		"/(app)/data/hang-gliding-sites": [~50,[2]],
		"/(app)/data/hang-gliding-sites/new": [56,[2]],
		"/(app)/data/hang-gliding-sites/[id=id]": [~51,[2]],
		"/(app)/data/hang-gliding-sites/[id=id]/discussion": [~52,[2]],
		"/(app)/data/hang-gliding-sites/[id=id]/edit": [~53,[2,13]],
		"/(app)/data/hang-gliding-sites/[id=id]/rfc/diff": [~54,[2]],
		"/(app)/data/hang-gliding-sites/[id=id]/rfc/history": [~55,[2]],
		"/(app)/data/hotspots": [~57,[2]],
		"/(app)/data/hotspots/new": [63,[2]],
		"/(app)/data/hotspots/[id=id]": [~58,[2]],
		"/(app)/data/hotspots/[id=id]/discussion": [~59,[2]],
		"/(app)/data/hotspots/[id=id]/edit": [~60,[2,14]],
		"/(app)/data/hotspots/[id=id]/rfc/diff": [~61,[2]],
		"/(app)/data/hotspots/[id=id]/rfc/history": [~62,[2]],
		"/(app)/data/latest-changes": [~64,[2]],
		"/(app)/data/navaids": [~65,[2]],
		"/(app)/data/navaids/new": [71,[2]],
		"/(app)/data/navaids/[id=id]": [~66,[2]],
		"/(app)/data/navaids/[id=id]/discussion": [~67,[2]],
		"/(app)/data/navaids/[id=id]/edit": [~68,[2,15]],
		"/(app)/data/navaids/[id=id]/rfc/diff": [~69,[2]],
		"/(app)/data/navaids/[id=id]/rfc/history": [~70,[2]],
		"/(app)/data/obstacles": [~72,[2]],
		"/(app)/data/obstacles/[id=id]": [~73,[2]],
		"/(app)/data/obstacles/[id=id]/discussion": [~74,[2]],
		"/(app)/data/rc-airfields": [~75,[2]],
		"/(app)/data/rc-airfields/new": [81,[2]],
		"/(app)/data/rc-airfields/[id=id]": [~76,[2]],
		"/(app)/data/rc-airfields/[id=id]/discussion": [~77,[2]],
		"/(app)/data/rc-airfields/[id=id]/edit": [~78,[2,16]],
		"/(app)/data/rc-airfields/[id=id]/rfc/diff": [~79,[2]],
		"/(app)/data/rc-airfields/[id=id]/rfc/history": [~80,[2]],
		"/(app)/data/reporting-points": [~82,[2]],
		"/(app)/data/reporting-points/new": [88,[2]],
		"/(app)/data/reporting-points/[id=id]": [~83,[2]],
		"/(app)/data/reporting-points/[id=id]/discussion": [~84,[2]],
		"/(app)/data/reporting-points/[id=id]/edit": [~85,[2,17]],
		"/(app)/data/reporting-points/[id=id]/rfc/diff": [~86,[2]],
		"/(app)/data/reporting-points/[id=id]/rfc/history": [~87,[2]],
		"/(app)/docs": [89,[2,18]],
		"/(app)/legal": [90,[2,19]],
		"/(app)/map": [91,[20]],
		"/(app)/notifications": [~92,[2]],
		"/(app)/tools/airspaces": [93,[21]],
		"/(app)/user/api-clients": [~94,[2,22]],
		"/(app)/user/comments": [~95,[2,22]],
		"/(app)/user/downloads": [~96,[2,22]],
		"/(app)/user/profile": [~97,[2,22]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';